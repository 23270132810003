// @ts-nocheck
import React, { useState, useCallback, useEffect, useRef } from 'react';
import nprogress from 'nprogress';
import { ClientCompany } from 'services';

import { useReactToPrint } from 'react-to-print';
import FileViewer from 'react-file-viewer';

import { useForm } from 'react-hook-form';

import {
  useToast,
  Button,
  ButtonGroup,
  VStack,
  Badge,
  chakra,
  Box,
  Icon,
  InputGroup,
  Text,
  HStack,
  Stack,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Link as LinkChakra,
  Modal as ModalChakra,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormLabel,
  Flex,
  Spacer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  List,
  ListItem,
} from '@chakra-ui/react';

import slugify from 'react-slugify';
import Modal from 'components/Modal';
import { Input } from 'components/Forms/Input';
import { Radio } from 'components/Forms/Radio';
import { Textarea } from 'components/Forms/Textarea';

import { RiMore2Fill, RiEdit2Fill, RiDeleteBin2Fill, RiDownload2Fill, RiAddFill, RiAddLine } from 'react-icons/ri';
import { FiImage, FiFile } from 'react-icons/fi';
import { FcFolder } from 'react-icons/fc';

import {
  GrDocumentPdf,
  GrDocumentZip,
  GrDocumentTxt,
  GrDocumentWord,
  GrDocumentCsv,
  GrDocumentExcel,
  GrDocumentPpt,
  GrDocumentVideo,
  GrDocumentSound,
  GrDocumentRtf,
} from 'react-icons/gr';

import Delete from '../documents/delete';

import { useCan } from 'hooks/useCan';
import CnpjCard from './cnpjCard';
import { Link } from 'react-router-dom';
import Reset from './reset';
import { MdLoop } from 'react-icons/md';

export default ({
  companyId,
  companyUf,
  licenseId,
  companyName,
  dataItem,
  isOpenLicense,
  onCloseLicense = () => { },
}) => {
  const componentRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenUpload, onOpen: onOpenUpload, onClose: onCloseUpload } = useDisclosure()
  const [document, setDocument] = useState('');
  const [documentHref, setDocumentHref] = useState('');

  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [filesLicenses, setFilesLicenses] = useState([]);
  const [repository, setRepository] = useState([]);
  const [selectedsFiles, setSelectedsFiles] = useState(null);
  const [selectedsFeeFiles, setSelectedsFeeFiles] = useState(null);
  const [selectedsProtocolFiles, setSelectedsProtocolFiles] = useState(null);
  const [typeModalUpload, setTypeModalUpload] = useState(null);
  const [repositoryUploadLicense, setRepositoryUploadLicense] = useState(null);
  const [fileFeeRef, setFileFeeRef] = useState('');
  const [fileRef, setFileRef] = useState('');
  const [newFileFeeName, setNewFileFeeName] = useState('');
  const [newFileName, setNewFileName] = useState('');
  const econformCan = useCan({ access_role: ['ECONFORM'] });
  const editorCan = useCan({ access_role: ['EDITOR'] });
  const deleteFilesCan = useCan({ permission_role: ['DELETE_FILES'] });
  const [resetItem, setResetItem] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [addNewFileRepository, setAddNewFileRepository] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm(/* { resolver: yupResolver(schema) } */);
  const [haveFee, setHaveFee] = useState('');
  const [accessoryObligations, setAccessoryObligations] = useState(haveFee);
  /* { resolver: yupResolver(schema) } */

  const handleConstruct = useCallback(async () => {
    setLoading(true);
    try {
      nprogress.start();
      const { data } = await ClientCompany.licenseRead(companyId, licenseId);
      nprogress.done();
      console.log('LICENSE-data: ', data);
      setHaveFee(String(Number(!!data.conditions)));
      reset({
        ...data,
        typeCustom: data?.type ? data.type : !data?.isNecessary ? 'not_necessary' : null,
        haveValidity: Number(!!data?.validUntil),
        validUntil: data?.validUntil?.slice(0, 10),
        accessoryObligations: Number(!!data?.feeAmount),
        feeExpirationDate: data.feeExpirationDate?.slice(0, 10),
      });
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar a licença/certidão.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
    setLoading(false);
  }, [companyId, licenseId]);

  const getFiles = async () => {
    try {
      const { data } = await ClientCompany.fileIndex(
        companyId,
        dataItem.clientNationalRegistrationId,
        dataItem.fiscalLicenseId
      );
      setFilesLicenses(data);
    } catch (error) {
      toast({
        title: 'Não foi possível carregar os documentos.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const getReadFile = async (fileId) => {
    try {
      const { data } = await ClientCompany.repositoryRead(
        companyId,
        fileId
      );
      setDocumentHref(data);
    } catch (error) {
      toast({
        title: 'Não foi possível carregar o documento.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const getRepository = async () => {
    try {
      const { data } = await ClientCompany.repositoryIndex(companyId);
      console.log('Repositorio: ', data)
      setRepository(data?.items?.sort(ascName));
    } catch (error) {
      toast({
        title: 'Não foi possível carregar o repositorio.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const uploadRepository = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('file_type', typeModalUpload);
      await ClientCompany.repositoryUpload(companyId, formData);
      setRepositoryUploadLicense(null)
      getRepository()
      toast({
        title: 'Arquivo enviado ao repositório com sucesso',
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Não foi possível carregar o arquivo.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const onChangeInputFileFee = (e) => {
    setFileFeeRef(e.target.files[0]);
    let file = e.target.files[0].name.toLowerCase();
    let filenameSeparated = file.split('.');
    let extension = filenameSeparated.pop();
    let filename = file
      .replace('.' + extension, '')
      .replace(' ', '-')
      .replace('.', '-');
    setNewFileFeeName(slugify(filename) + '.' + extension);
  };

  const onChangeInputFile = (e) => {
    setFileRef(e.target.files[0]);
    let file = e.target.files[0].name.toLowerCase();
    let filenameSeparated = file.split('.');
    let extension = filenameSeparated.pop();
    let filename = file
      .replace('.' + extension, '')
      .replace(' ', '-')
      .replace('.', '-');
    setNewFileName(slugify(filename) + '.' + extension);
  };

  const handleOnSubmit = useCallback(
    async (data) => {
      console.log('SUBMIT DATA', data)
      const fileId = data?.typeCustom === 'document' ? Number(selectedsFiles?.id) : data?.typeCustom === 'protocol' ? Number(selectedsProtocolFiles?.id) : null
      try {
        await ClientCompany.licenseUpdateItem(companyId, {
          feeFileId: Number(selectedsFeeFiles?.id),
          fileId: fileId,
          fiscalLicense: Number(dataItem?.fiscalLicenseId),
          clientNationalRegistrationId: dataItem?.clientNationalRegistrationId,
          fiscalRegistrationId: dataItem?.fiscalRegistrationId,
          /* file: newFileName || data?.file || null, */
          type: data?.typeCustom !== 'not_necessary' ? data.typeCustom : '',
          validUntil: data.validUntil,
          feeName: data.feeName,
          feeAmount: data.feeAmount,
          feeExpirationDate: data?.feeExpirationDate,
          conditions: data.conditions,
          /* feeFile: newFileFeeName || data?.feeFile || null, */
          note: data.note,
          isNecessary: data?.typeCustom === 'not_necessary' ? false : true,
          problem: data.problem,
          description: data.description,
        });

        toast({
          title: 'Alteração realizada',
          status: 'success',
          isClosable: true,
        });
        getFiles();
        setTimeout(() => {
          setNewFileName('');
          setNewFileFeeName('');
          setSelectedsFiles(null);
          setSelectedsFeeFiles(null);
          setSelectedsProtocolFiles(null);
          onCloseLicense();
        }, 1000);
      } catch (error) {
        console.log('ERRO AO SALVAR ', error.response.data);
        toast({
          title: 'Não foi possível alterar',
          description: error.response.data.message
            ? error.response.data.message
            : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [companyId, selectedsFiles, selectedsProtocolFiles, dataItem, newFileName, fileRef, newFileFeeName, fileFeeRef, onClose]
  );

  useEffect(() => {
    if ((dataItem.id || 0) > 0) {
      handleConstruct();
      getFiles();
      getRepository();
    }
  }, [handleConstruct]);

  const badgeStatus =
    watch('status') &&
    (watch('status') === 'in_conformity' ? (
      <Badge colorScheme="green">Em conformidade</Badge>
    ) : watch('status') === 'pending' ? (
      <Badge colorScheme="yellow">Pendente</Badge>
    ) : watch('status') === 'license_expired' ? (
      <Badge colorScheme="red">Vencida</Badge>
    ) : watch('status') === 'near_expiration' ? (
      <Badge colorScheme="purple">Expirando</Badge>
    ) : watch('status') === 'renewing' ? (
      <Badge colorScheme="blue">Renovando</Badge>
    ) : watch('status') === 'not_necessary' ? (
      <Badge colorScheme="grayBlue">Não necessária</Badge>
    ) : (
      ''
    ));

  function IconFile({ extension }) {
    return (
      <Box mr="2" align="center">
        {extension === 'folder' ? (
          <Icon as={FcFolder} boxSize="1rem" />
        ) : extension === 'jpg' ||
          extension === 'jpeg' ||
          extension === 'png' ||
          extension === 'gif' ||
          extension === 'bmp' ||
          extension === 'tiff' ||
          extension === 'cdr' ||
          extension === 'ai' ? (
          <Icon as={FiImage} boxSize="1rem" color="orange.400" />
        ) : extension === 'pdf' ? (
          <Icon as={GrDocumentPdf} boxSize="1rem" color="red.400" sx={{ path: { stroke: 'red.500' } }} />
        ) : extension === 'zip' ? (
          <Icon as={GrDocumentZip} boxSize="1rem" color="green.500" sx={{ path: { stroke: 'green.400' } }} />
        ) : extension === 'csv' ? (
          <Icon as={GrDocumentCsv} boxSize="1rem" color="cyan.400" sx={{ path: { stroke: 'cyan.400' } }} />
        ) : extension === 'rtf' ? (
          <Icon as={GrDocumentRtf} boxSize="1rem" color="cyan.400" sx={{ path: { stroke: 'cyan.400' } }} />
        ) : extension === 'doc' || extension === 'docx' ? (
          <Icon as={GrDocumentWord} boxSize="1rem" color="blue.500" sx={{ path: { stroke: 'blue.500' } }} />
        ) : extension === 'xls' || extension === 'xlsx' || extension === 'xlsb' ? (
          <Icon as={GrDocumentExcel} boxSize="1rem" color="green.500" sx={{ path: { stroke: 'green.400' } }} />
        ) : extension === 'ppt' || extension === 'pptx' ? (
          <Icon as={GrDocumentPpt} boxSize="1rem" color="pink.400" sx={{ path: { stroke: 'pink.400' } }} />
        ) : extension === 'mp4' ||
          extension === 'mpeg' ||
          extension === 'avi' ||
          extension === 'mpg' ||
          extension === 'mpg' ||
          extension === 'mov' ? (
          <Icon as={GrDocumentVideo} boxSize="1rem" color="purple.400" sx={{ path: { stroke: 'purple.400' } }} />
        ) : extension === 'mp3' || extension === 'wav' || extension === 'ogg' || extension === 'wma' ? (
          <Icon as={GrDocumentSound} boxSize="1rem" color="orange.400" sx={{ path: { stroke: 'orange.400' } }} />
        ) : extension === 'txt' ? (
          <Icon as={GrDocumentTxt} boxSize="1rem" color="gray.600" sx={{ path: { stroke: 'gray.600' } }} />
        ) : (
          <Icon as={FiFile} boxSize="1rem" color="gray.400" sx={{ path: { stroke: 'gray.400' } }} />
        )}
      </Box>
    );
  }
  function BoxFile({ id, name, extension, href, type, status }) {
    return (
      <HStack
        key={id}
        p="2"
        cursor="pointer"
        _hover={{ backgroundColor: 'grayBlue.100' }}
        title={name}
        align="center"
        justify="space-between"
      >
        <HStack
          align="center"
          onClick={() => {
            setDocument({ id, name, href, status, type });
            getReadFile(id)
            onOpen();
          }}
        >
          <IconFile extension={extension} />
          <VStack spacing="0.5" align="start">
            <Text fontSize="xs" color="grayBlue.800" fontWeight="semibold" noOfLines={3} textTransform="capitalize">
              {name}
            </Text>
          </VStack>
        </HStack>
        <Box>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<Icon as={RiMore2Fill} w={6} h={6} />}
              variant="unstyle"
              minW="auto"
              color="grayBlue.400"
              _hover={{ color: 'blue.400' }}
            />
            <MenuList>
              <MenuItem icon={<RiEdit2Fill />} onClick={() => window.open(href, '_blank')}>
                Abrir
              </MenuItem>
              <MenuItem icon={<RiDeleteBin2Fill />} onClick={() => setDeleteItem(Number(id))} color="red.500">
                Excluir
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
    );
  }

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '{}',
    copyStyles: true,
    /* fonts: {
      family: "'Roboto', sans-serif",
      source: "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap";
    }[], */
  });

  const linksAndFonts = [
    {
      uf: 'SP',
      items: [
        {
          name: 'RedeSim',
          links: [
            {
              name: 'Consulta Pública de Licenciamento',
              link: 'https://www.jucesp.sp.gov.br/IntegradorPaulista/ConsultaPublica',
            },
          ],
        },
        {
          name: 'Prefeitura',
          links: [
            {
              name: 'Consulta Pública de Licença Municipal',
              link: 'https://e-licenca.prefeitura.sp.gov.br/LicenciamentoInternet/ConsultaLicenca',
            },
          ],
        },
        {
          name: 'Bombeiro',
          links: [
            {
              name: 'Consulta Licenças "por endereço"',
              link: 'https://viafacil2.policiamilitar.sp.gov.br/sgsci/publico/pesquisaravcblogradouro.aspx',
            },
          ],
        },
        {
          name: 'RedeSim',
          links: [
            {
              name: 'Consulta Licenças "por endereço"',
              link: 'https://vreredesim.sp.gov.br/home',
            },
          ],
        },
      ],
    },
    {
      uf: 'ES',
      items: [
        {
          name: 'Simplifica',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.simplifica.es.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'PB',
      items: [
        {
          name: 'RedeSim',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.redesim.pb.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'PR',
      items: [
        {
          name: 'Empresa Fácil',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.empresafacil.pr.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'GO',
      items: [
        {
          name: 'Portal do Empreendedor Goiano',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.portaldoempreendedorgoiano.go.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'MA',
      items: [
        {
          name: 'Empresa Fácil',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.empresafacil.ma.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'RO',
      items: [
        {
          name: 'Empresa Fácil',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.empresafacil.ro.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'MG',
      items: [
        {
          name: 'RedeSim',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://redesim.mg.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'PI',
      items: [
        {
          name: 'Piaui Digital',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.piauidigital.pi.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'RN',
      items: [
        {
          name: 'RedeSim',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.redesim.rn.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'AL',
      items: [
        {
          name: 'Facilita',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.facilita.al.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'PE',
      items: [
        {
          name: 'Jucepe',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://portal.jucepe.pe.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'AM',
      items: [
        {
          name: 'Jucea',
          links: [
            {
              name: 'Consultas diversas',
              link: 'http://jucea.am.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'AC',
      items: [
        {
          name: 'Rede Simples',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://redesimples.ac.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'RS',
      items: [
        {
          name: 'JucisRS',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://jucisrs.rs.gov.br/inicial',
            },
          ],
        },
      ],
    },
    {
      uf: 'BA',
      items: [
        {
          name: 'Juceb',
          links: [
            {
              name: 'Consultas diversas',
              link: 'http://www.juceb.ba.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'PA',
      items: [
        {
          name: 'Jucepa',
          links: [
            {
              name: 'Consultas diversas',
              link: 'http://jucepa.pa.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'TO',
      items: [
        {
          name: 'Simplifica',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.simplifica.to.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'SE',
      items: [
        {
          name: 'Agiliza',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.agiliza.se.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'CE',
      items: [
        {
          name: 'Jucec',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.jucec.ce.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'MT',
      items: [
        {
          name: 'Rede Simples',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://redesimples.mt.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'MS',
      items: [
        {
          name: 'Rede Simples',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://redesimples.ms.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'RJ',
      items: [
        {
          name: 'Jucerja',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.jucerja.rj.gov.br/',
            },
            {
              name: 'Portal Carioca Digital',
              link: 'https://carioca.rio',
            },
          ],
        },
      ],
    },
    {
      uf: 'DF',
      items: [
        {
          name: 'Rede Simples',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://redesimples.df.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'SC',
      items: [
        {
          name: 'Jucesc',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://www.jucesc.sc.gov.br/',
            },
          ],
        },
      ],
    },
    {
      uf: 'RR',
      items: [
        {
          name: 'Rede Simples',
          links: [
            {
              name: 'Consultas diversas',
              link: 'https://redesimples.rr.gov.br/',
            },
          ],
        },
      ],
    },
  ];

  const handleCloseModalLicense = () => {
    onCloseLicense()
    setSelectedsFiles(null)
    setSelectedsProtocolFiles(null)
    setSelectedsFeeFiles(null)
  }

  const selectByRepository = (selected) => {
    console.log('Add selected', selected)
    typeModalUpload === 'document' ?
      setSelectedsFiles(selected) :
      typeModalUpload === 'protocol' ?
        setSelectedsProtocolFiles(selected) :
        setSelectedsFeeFiles(selected)
    /* setRepository([...repository?.filter(item => item.id !== selected.id), selected]) */
    /* if (selectedsFiles.every(item => item !== selected)) {
      setSelectedsFiles([...selectedsFiles.filter(item => item.id !== selected.id), selected])
      setRepository([...repository?.filter(item => item.id !== selected.id)])
    } */
  }

  const removeBySelecteds = (selected) => {
    console.log('Remove selected', selected)
    typeModalUpload === 'document' ?
      setSelectedsFiles(null) :
      typeModalUpload === 'protocol' ?
        setSelectedsProtocolFiles(null) :
        setSelectedsFeeFiles(null)
    /* setSelectedsFiles(selectedsFiles?.filter(item => item.id !== selected.id))
    setRepository([...repository?.filter(item => item.id !== selected.id), selected]) */
  }
  function ascName(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  const handleOpenUpload = (type) => {
    onOpenUpload()
    setTypeModalUpload(type)
  }

  const handleCloseUpload = (type) => {
    onCloseUpload()
    setTypeModalUpload(null)
  }

  return (
    <>
      <Modal
        title={
          <>
            {dataItem?.fiscalLicenseName} {badgeStatus}
          </>
        }
        size="lg"
        isOpen={isOpenLicense}
        onClose={handleCloseModalLicense}
        loading={loading}
        isSubmitting={isSubmitting}
        footer={
          <ButtonGroup spacing="4">
            <Button type="submit" form="formRef" isLoading={isSubmitting}>
              Salvar
            </Button>
            <Button colorScheme="red" onClick={() => setResetItem(Number(licenseId))} isLoading={isSubmitting}>
              Resetar
            </Button>
            <Button
              colorScheme="grayBlue"
              variant="ghost"
              onClick={() => {
                reset({
                  fiscalLicense: null,
                  validUntil: null,
                  file: null,
                });
                onCloseLicense();
              }}
            >
              Fechar
            </Button>
          </ButtonGroup>
        }
      >
        <VStack as="form" id="formRef" w="100%" loading={loading} onSubmit={handleSubmit(handleOnSubmit)} spacing={10} p={4}>
          <Box w="100%" p={8} bg="grayBlue.200" borderRadius="5px">
            <Radio
              name="typeCustom"
              label="Tipo"
              defaultValue={watch('typeCustom')}
              options={[
                { label: 'Licença', value: 'document' },
                { label: 'Protocolo', value: 'protocol' },
                { label: 'Não necessária', value: 'not_necessary' },
              ]}
              errors={errors}
              variant="unstyled"
              register={register}
            />
          </Box>

          {/* DADOS */}
          <>
            {/* <Text
              w='full'
              fontWeight="semibold"
              fontSize="xl"
              textTransform={'uppercase'}
              color="blue.500"
              textAlign="left"
              borderColor='grayBlue.200'
              borderBottom='1px'
            >
              DADOS
            </Text> */}

            {(watch('typeCustom') === 'document' || watch('typeCustom') === 'protocol') ? (
              <>

                {/* <Radio
                name="type"
                label="Tipo"
                defaultValue={getValues('type') || 'document'}
                options={[
                  { label: 'Licença/Certidão', value: 'document' },
                  { label: 'Protocolo', value: 'protocol' },
                ]}
                errors={errors}
                variant="unstyled"
                register={register}
                /> */}

                <Radio
                  name="haveValidity"
                  label="Tem validade?"
                  defaultValue={dataItem?.status === 'pending' ? '' : String(Number(!!dataItem.validUntil))}
                  options={[
                    { label: 'Sim', value: '1' },
                    { label: 'Não', value: '0' },
                  ]}
                  errors={errors}
                  variant="unstyled"
                  register={register}
                />

                {watch('haveValidity') != '0' && (
                  <>
                    <Input
                      name="validUntil"
                      type="date"
                      label={`Validade`}
                      disabled={watch('haveValidity') == '0'}
                      errors={errors}
                      register={register}
                    />
                  </>
                )}
              </>
            ) : (watch('typeCustom') === 'not_necessary') ? (
              <>
                <Radio
                  name="problem"
                  label="Não necessário em função de imóvel localizado em:"
                  defaultValue={dataItem.problem}
                  options={[
                    { label: 'Shopping', value: 'shopping' },
                    { label: 'Centro comercial', value: 'centro_comercial' },
                    { label: 'Residencial', value: 'residencial' },
                    { label: 'Logradouro', value: 'logradouro' },
                    { label: 'Sala comercial', value: 'sala_comercial' },
                    { label: 'Operador logístico', value: 'operador_logistico' },
                  ]}
                  errors={errors}
                  variant="unstyled"
                  register={register}
                />
                <Textarea
                  name="description"
                  label="Outros motivos:"
                  placeholder="Descreva aqui..."
                  errors={errors}
                  variant="unstyled"
                  register={register}
                />
              </>
            ) : ''}
          </>

          {/* ARQUIVOS */}
          {(watch('typeCustom') === 'document' || watch('typeCustom') === 'protocol') && (
            <VStack w='full' align='start'>

              <Flex w='full' gap={4}>
                <Box w='30%'>
                  <Text w='full' fontWeight="semibold" pe='4' fontSize="md" color="grayBlue.500" textAlign="left">
                    Upload
                  </Text>
                </Box>
                <Box w='70%'>
                  <Button size='sm' onClick={() => handleOpenUpload(watch('typeCustom'))}>Adicionar</Button>
                  <Box w='full'>
                    {selectedsFiles ?
                      <BoxFile
                        id={selectedsFiles?.id}
                        name={selectedsFiles?.name}
                        extension={selectedsFiles?.name?.split('.').pop()}
                        href={selectedsFiles?.href}
                        type={selectedsFiles?.file_type}
                      />
                      :
                      selectedsProtocolFiles ?
                        <BoxFile
                          id={selectedsProtocolFiles?.id}
                          name={selectedsProtocolFiles?.name}
                          extension={selectedsProtocolFiles?.name?.split('.').pop()}
                          href={selectedsProtocolFiles?.href}
                          type={selectedsProtocolFiles?.file_type}
                        />
                        :
                        watch('files')?.documents?.length > 0 ||
                          watch('files')?.protocols?.length > 0 ? (
                          <SimpleGrid columns={[1]} spacing="2" w='full'>
                            {watch('files')?.documents?.map((doc) => (
                              <BoxFile
                                id={doc?.id}
                                name={doc?.name}
                                extension={doc?.name?.split('.').pop()}
                                href={doc?.href}
                                type={doc?.file_type}
                              />
                            ))}
                            {watch('files')?.protocols?.map((doc) => (
                              <BoxFile
                                id={doc?.id}
                                name={doc?.name}
                                extension={doc?.name?.split('.').pop()}
                                href={doc?.href}
                                type={doc?.file_type}
                              />
                            ))}
                          </SimpleGrid>
                        )
                          : dataItem?.fiscalLicenseName?.includes('CNPJ') ? (
                            <Box w="full">
                              <BoxFile id={0} name={'Cartão CNPJ'} extension={'pdf'} href={'#'} type={'document'} />
                            </Box>
                          ) : (
                            <Text fontSize="xs" fontWeight="medium" color="grayBlue.400" mt='8'>
                              Nenhum arquivo adicionado
                            </Text>
                          )}
                  </Box>
                </Box>
              </Flex>


              {(econformCan || editorCan) && (
                <Box w='full' bg='grayBlue.200' p='4' borderRadius='4' mt="8">
                  <Text fontWeight="semibold" fontSize="xs" color="grayBlue.500" textAlign="left" mb='4'>
                    Links e fontes - {companyUf}
                  </Text>
                  <VStack w='full' align="start" spacing="0" fontSize="xs">
                    {linksAndFonts?.map(
                      (linkfont) =>
                        companyUf === linkfont?.uf &&
                        linkfont?.items?.map((item, i) => (
                          <div key={i}>
                            <chakra.span color="grayBlue.500">{item?.name}: </chakra.span>
                            {item?.links?.map((link, idx) => (
                              <LinkChakra key={idx} href={link?.link} isExternal>
                                {link?.name}
                              </LinkChakra>
                            ))}
                          </div>
                        ))
                    )}
                  </VStack>
                </Box>
              )}
            </VStack>
          )}

          {/* CONDICIONANTES */}
          {(watch('typeCustom') === 'document' || watch('typeCustom') === 'protocol') && (
            <>
              {/* <Text
                w='full'
                fontWeight="semibold"
                fontSize="xl"
                textTransform={'uppercase'}
                color="blue.500"
                textAlign="left"
                borderColor='grayBlue.200'
                borderBottom='1px'
              >
                Condicionantes
              </Text> */}
              <Radio
                name="haveFee"
                label="Possui condicionantes?"
                defaultValue={String(Number(!!watch('conditions')))}
                options={[
                  { label: 'Sim', value: '1' },
                  { label: 'Não', value: '0' },
                ]}
                onChange={(e) => setHaveFee(e.target.value)}
                errors={errors}
                variant="unstyled"
                register={register}
              />

              {haveFee != '0' && (
                <>
                  <Textarea
                    name="conditions"
                    label="Condicionantes"
                    isDisabled={haveFee == '0'}
                    placeholder="Descreva aqui..."
                    errors={errors}
                    register={register}
                  />

                  <Radio
                    name="accessoryObligations"
                    label="É necessário recolhimento de taxa?"
                    isDisabled={haveFee == '0'}
                    defaultValue={dataItem?.status === 'pending' ? '0' : String(Number(!!dataItem?.feeAmount))}
                    options={[
                      { label: 'Sim', value: '1' },
                      { label: 'Não', value: '0' },
                    ]}
                    onChange={(e) => setAccessoryObligations(e.target.value)}
                    errors={errors}
                    variant="unstyled"
                    register={register}
                  />

                  <Input
                    name="feeName"
                    label="Nome da taxa"
                    disabled={haveFee == '0' || accessoryObligations == '0'}
                    errors={errors}
                    register={register}
                  />

                  <Input
                    name="feeAmount"
                    label="Valor da taxa"
                    disabled={haveFee == '0' || accessoryObligations == '0'}
                    errors={errors}
                    register={register}
                  />

                  <Input
                    name="feeExpirationDate"
                    type="date"
                    label="Vencimento da taxa"
                    disabled={haveFee == '0' || accessoryObligations == '0'}
                    errors={errors}
                    register={register}
                  />

                  <Flex w='full' gap={4}>
                    <Box w='30%'>
                      <Text w='full' fontWeight="semibold" fontSize="md" color="grayBlue.500" textAlign="left">
                        Upload da Taxa
                      </Text>
                    </Box>
                    <Box w='70%'>
                      <Button size='sm' onClick={() => handleOpenUpload('fee')}>Adicionar</Button>
                      <Box w='full'>

                        {selectedsFeeFiles ?
                          <BoxFile
                            id={selectedsFeeFiles?.id}
                            name={selectedsFeeFiles?.name}
                            extension={selectedsFeeFiles?.name?.split('.').pop()}
                            href={selectedsFeeFiles?.href}
                            type={selectedsFeeFiles?.file_type}
                          />
                          :
                          watch('files')?.fees?.length > 0 ? (
                            <SimpleGrid columns={[1]} spacing="2" w='full'>
                              {watch('files')?.fees?.map((doc) => (
                                <BoxFile
                                  id={doc?.id}
                                  name={doc?.name}
                                  extension={doc?.name?.split('.').pop()}
                                  href={doc?.href}
                                  type={doc?.file_type}
                                />
                              ))}
                            </SimpleGrid>
                          ) : (
                            <Text fontSize="xs" fontWeight="medium" color="grayBlue.400" mt='8'>
                              Nenhum arquivo adicionado
                            </Text>
                          )}
                      </Box>
                    </Box>
                  </Flex>

                </>
              )}

              <Textarea
                name="note"
                label="Observações"
                disabled={haveFee == '0' || accessoryObligations == '0'}
                placeholder="Descreva aqui..."
                errors={errors}
                register={register}
              />
            </>
          )}
        </VStack>
      </Modal>

      {/* REPOSITORIO */}
      <ModalChakra isOpen={isOpenUpload} onClose={handleCloseUpload} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Repositório {typeModalUpload === 'fee' ? ' de Taxas' : typeModalUpload === 'document' ? ' de Licenças' : 'de Protocolos'}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>

            <Text fontWeight="semibold" fontSize="md" color="grayBlue.500" textAlign="left" mb='4'>
              Escolhido <small style={{ fontSize: 10, color: 'grauBlue.400' }}>(clique para remover)</small>
            </Text>

            {typeModalUpload === 'document' ?
              <List>
                {!selectedsFiles ?
                  <Text fontSize="xs" fontWeight="medium" color="grayBlue.400">
                    Nenhum arquivo escolhido
                  </Text>
                  : <ListItem cursor='pointer' _hover={{ color: 'red.500' }} onClick={() => removeBySelecteds(selectedsFiles)}>
                    <HStack>
                      <IconFile extension={selectedsFiles.type} />
                      <span>{selectedsFiles.name}</span>
                    </HStack>
                  </ListItem>
                }
              </List>
              :
              typeModalUpload === 'fee' ?
                <List>
                  {!selectedsFeeFiles ?
                    <Text fontSize="xs" fontWeight="medium" color="grayBlue.400">
                      Nenhum arquivo escolhido
                    </Text>
                    : <ListItem cursor='pointer' _hover={{ color: 'red.500' }} onClick={() => removeBySelecteds(selectedsFeeFiles)}>
                      <HStack>
                        <IconFile extension={selectedsFeeFiles.type} />
                        <span>{selectedsFeeFiles.name}</span>
                      </HStack>
                    </ListItem>
                  }
                </List>
                :
                <List>
                  {!selectedsProtocolFiles ?
                    <Text fontSize="xs" fontWeight="medium" color="grayBlue.400">
                      Nenhum arquivo escolhido
                    </Text>
                    : <ListItem cursor='pointer' _hover={{ color: 'red.500' }} onClick={() => removeBySelecteds(selectedsProtocolFiles)}>
                      <HStack>
                        <IconFile extension={selectedsProtocolFiles.type} />
                        <span>{selectedsProtocolFiles.name}</span>
                      </HStack>
                    </ListItem>
                  }
                </List>
            }

            <Box bg='grayBlue.200' p='8' borderRadius='4' mt="8">

              <Flex justify='space-between' align='center'>
                <HStack>
                  <Text fontWeight="semibold" fontSize="md" color="grayBlue.500" textAlign="left">
                    Selecione do repositório
                  </Text>
                  <IconButton size='xs' color='grayBlue.600' colorScheme='gray' variant='ghost' ms='4' onClick={() => getRepository()} icon={<MdLoop />} />
                </HStack>
                <Button size='xs' colorScheme={addNewFileRepository ? 'gray' : 'blue'} leftIcon={<RiAddLine />} onClick={() => setAddNewFileRepository(!addNewFileRepository)}>Novo</Button>
              </Flex>


              {addNewFileRepository &&
                <Box bg='grayBlue.300' p='4' my="3" borderRadius={5}>
                  <Text fontWeight="medium" fontSize="xs" color="grayBlue.500" textAlign="left">
                    Novo arquivo no repositório
                  </Text>
                  <InputGroup size='sm'>
                    <Input
                      name="file"
                      type="file"
                      onChange={(e) => setRepositoryUploadLicense(e.target.files[0])}
                      w='100%'
                    />
                    <Button onClick={() => uploadRepository(repositoryUploadLicense)}>Enviar</Button>
                  </InputGroup>
                </Box>
              }

              <Text fontWeight="medium" fontSize="xs" color="grayBlue.500" textAlign="left" mt="8" mb='2'>
                Clique para escolher
              </Text>

              <List>tipo: {typeModalUpload}
                {repository?.filter(item => item.file_type === typeModalUpload)?.sort(ascName)?.map((file, i) => (
                  <ListItem key={i} cursor='pointer' _hover={{ color: 'blue.500' }} onClick={() => selectByRepository(file)} >
                    <HStack>
                      <IconFile extension={file.type} />
                      <Text noOfLines={1}>{file.name}</Text>
                      {/* <IconButton size='xs' icon={<RiAddFill  />} /> */}
                    </HStack>
                  </ListItem>
                ))}
              </List>

            </Box>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button onClick={onCloseUpload}>Concluído</Button>
              <Button variant='ghost' onClick={() => { onCloseUpload(), setSelectedsFiles(null) }}>Cancelar</Button>
            </HStack>
          </ModalFooter>

        </ModalContent>
      </ModalChakra>

      {/* ABRINDO ARQUIVO */}
      <ModalChakra isOpen={isOpen} onClose={onClose} size="xxl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <span>
                {document?.type === 'document' ? 'Licença' : document?.type === 'protocol' ? 'Protocolo' : 'Taxa'}:{' '}
              </span>
              <IconFile extension={document?.name?.includes('CNPJ') ? 'pdf' : document?.name?.split('.').pop()} />

              <span>
                {document?.name} {badgeStatus}
              </span>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody bg="grayBlue.200">

            <Box mx="auto" textAlign="center">
              {document?.name?.includes('CNPJ') ? (
                <>
                  <Text mb="6" textAlign="left" color="blue.500" textTransform="capitalize">
                    <Button onClick={handlePrint} size="sm">
                      Download/Imprimir
                    </Button>
                  </Text>
                  <CnpjCard ref={componentRef} companyId={companyId} document={document} />
                </>
              ) : (
                <>
                  <Text mb="6" color="blue.500" textTransform="capitalize" textAlign="left">
                    <Button as={Link} to={document?.href} target="_blank" size="sm" variant="link">
                      Download
                    </Button>
                  </Text>
                  <Box minHeight="200px">
                    {(!!selectedsFiles || !!selectedsProtocolFiles) ?
                      <Text mb="6">Link temporário gerado pela AWS. <LinkChakra href={documentHref?.data} color="blue.500" isExternal>Clique aqui para abrir</LinkChakra></Text>
                      :
                      document?.name?.split('.').pop() === 'xlsb' ? (
                        'Este arquivo Excel contém filtros e códigos avançados para a exibição completa por aqui. Faça download e abra o mesmo localmente em seu sistema operacional.'
                      ) : (
                        <FileViewer
                          fileType={document?.name?.split('.').pop()}
                          filePath={document?.href}
                          styles={{ width: 'auto!important', minHeight: '300px!important' }}
                        />
                      )}
                  </Box>
                </>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </ModalChakra>

      {/* RESET */}
      {deleteFilesCan ? (
        <Reset
          id={resetItem}
          companyId={companyId}
          isOpen={!!resetItem}
          onClose={() => {
            setResetItem(0);
          }}
        />
      ) : null}

      {/* DELETE */}
      {deleteFilesCan ? (
        <Delete
          id={deleteItem}
          companyId={companyId}
          isOpen={!!deleteItem}
          onClose={() => {
            setDeleteItem(0);
            getFiles();
          }}
        />
      ) : null}
    </>
  );
};
