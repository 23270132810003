import api from 'services/api';

const timestamp = new Date().getTime();

const index = (args) => {
  return api.get(`/api/v1/client/company/?_locale=pt_br&timestamp=${timestamp}${args}`);
};

const exportItems = (clientId) => {
  return api.get(`/api/v1/client/export/company/${clientId}`);
};

const consultItem = (data) => {
  return api.post(`/api/v1/client/company/cnpj/cnae/level`, data);
};

const createItem = (data) => {
  return api.post(`/api/v1/client/company/cnpj`, data);
};

const importItem = (data) => {
  return api.post(`/api/v1/client/company/cnpj/file`, data);
};

const readItem = (companyId) => {
  return api.get(`/api/v1/client/company/${companyId}`);
};

const updateItem = (companyId, data) => {
  return api.put(`/api/v1/client/company/${companyId}`, data);
};

const updateDataItem = (companyId) => {
  return api.put(`/api/v1/client/company/cnpj/${companyId}`);
};

const lastUpdateDataItem = (companyId) => {
  return api.get(`/api/v1/client/company/update/${companyId}`);
};

const deleteItem = (companyId) => {
  return api.delete(`/api/v1/client/company/delete/${companyId}`);
};

const licensesStatus = (companyId, args) => {
  return api.get(`/api/v1/client/company/${companyId}/license-status${args}`);
};

const logoCreateItem = (companyId, file) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  return api.post(`/api/v1/s3/upload/company/logo/${companyId}`, file, config);
};

const logoDeleteItem = (companyId) => {
  return api.delete(`/api/v1/s3/delete/company/logo/${companyId}`);
};

const cnpjIndex = (companyId, args) => {
  return api.get(`/api/v1/client/company/${companyId}/cnpj/?${args}`);
};

const cnpjCreateItem = (companyId, data) => {
  return api.post(`/api/v1/client/company/cnpj/${companyId}`, data);
};

const cnpjDeleteItem = (companyId, data) => {
  return api.delete(`/api/v1/client/company/${companyId}`, data);
};

/* const cnaeIndex = (companyId, args) => {
  return api.get(`/api/v1/client/company/${companyId}/cnae/search/?${args}`);
}; */

const licenseIndex = (companyId, args) => {
  return api.get(`/api/v1/client/company/${companyId}/license${args}`);
};

const licenseRead = (companyId, licenseId) => {
  return api.get(`/api/v1/client/company/${companyId}/license/${licenseId}`);
};

const licenseUpdateItem = (companyId, data) => {
  return api.put(`/api/v1/client/company/${companyId}/license/`, data);
};

const licenseResetItem = (companyId, licenseId) => {
  return api.put(`/api/v1/client/company/${companyId}/license/${licenseId}`);
};

const licenseCreateItemNotNecessary = (data) => {
  return api.post(`/api/v1/client/company/license/not-necessary`, data);
};

const addressIndex = (companyId) => {
  return api.get(`/api/v1/client/company/${companyId}/address`);
};

const repositoryIndex = (companyId, args = '?limit=9999') => {
  return api.get(`/api/v1/client/company/${companyId}/file${args}`);
};

const repositoryUpload = (companyId, data) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  return api.post(`/api/v1/s3/upload/company/document/${companyId}`, data, config);
};

const repositoryRead = (companyId, fileId) => {
  return api.get(`/api/v1/s3/read/company/document/${companyId}/${fileId}`);
};

const fileIndex = (companyId, cnpj, cnae, args) => {
  let url = `/api/v1/client/company/${companyId}/file`;

  if (cnpj !== '') {
    url += `/${cnpj}`;
  }

  if (cnae !== '') {
    url += `/${cnae}`;
  }

  return api.get(url + `?_locale=pt_BR&timestamp=${timestamp}${args}`);
};

const fileDeleteItem = (id, companyId) => {
  return api.delete(`/api/v1/client/company/${companyId}/file/${id}`);
};

const tagsIndex = (args) => {
  return api.get(`/api/v1/client/tags${args}`);
};

const tagsCreate = (data) => {
  return api.post(`/api/v1/client/tags`, data);
};

const tagsUpdate = (companyId, data) => {
  return api.put(`/api/v1/client/tags/${companyId}`, data);
};

const tagsDelete = (tagId) => {
  return api.delete(`/api/v1/client/tags/${tagId}/`);
};

export default {
  index,
  exportItems,
  consultItem,
  createItem,
  importItem,
  readItem,
  updateItem,
  updateDataItem,
  lastUpdateDataItem,
  deleteItem,
  logoCreateItem,
  logoDeleteItem,
  cnpjIndex,
  cnpjCreateItem,
  cnpjDeleteItem,
  /* cnaeIndex, */
  licenseIndex,
  licenseRead,
  licenseUpdateItem,
  licenseResetItem,
  licenseCreateItemNotNecessary,
  addressIndex,
  repositoryIndex,
  repositoryUpload,
  repositoryRead,
  fileIndex,
  fileDeleteItem,
  licensesStatus,
  tagsIndex,
  tagsCreate,
  tagsUpdate,
  tagsDelete,
};
