import React, { forwardRef } from 'react';
import { Box, FormControl, FormErrorMessage, FormHelperText, FormLabel, Textarea as TextareaChakra } from '@chakra-ui/react';

const TextareaBase = (
  { name = '', label = '',
    placeholder = '', register = () => { },
    errors = {}, helper = null,
    layout = 'horizontal',
    ...rest },
  ref
) => {
  return (
    <FormControl isInvalid={errors[name]} {...rest}>

      <div style={{ display: layout === 'horizontal' ? 'flex' : 'block' }}>

        {!!label && <FormLabel w={layout === 'horizontal' ? '30%' : '100%'}>{label}</FormLabel>}

        <Box w={layout === 'horizontal' ? '70%' : '100%'}>
          <TextareaChakra placeholder={placeholder} ref={ref} {...register(name)} />
          {helper && <FormHelperText>{helper}</FormHelperText>}
          {errors[name] && <FormErrorMessage>{errors[name].message}</FormErrorMessage>}
        </Box>

      </div>
    </FormControl>
  );
};

export const Textarea = forwardRef(TextareaBase);
