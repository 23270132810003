// @ts-nocheck
import React, { useState } from 'react';
import nprogress from 'nprogress';
import { ClientCompany } from 'services';
import { useQuery } from '@tanstack/react-query';
import { Link as LinkRouterDom } from 'react-router-dom';

import {
  chakra,
  useToast,
  Button,
  Input,
  ButtonGroup,
  Link,
  Text,
  Heading,
  Badge,
  Box,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  VStack,
  Icon,
  Spinner,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Tag,
  TagLabel,
  TagCloseButton,
  Divider,
  Portal,
} from '@chakra-ui/react';

import Breadcrumbs from 'components/Breadcrumbs';
import ButtonSearch from 'components/ButtonSearch';
import { Content, Paper, Card } from 'components/Content';
import Pagination from 'components/Pagination';
import Result from 'components/Result';
import ShowingItemsLegend from 'components/Tables/ShowingItemsLegend';
import ItemsPerPage from 'components/Tables/ItemsPerPage';

import { useDelayedValue } from 'hooks/useDelayedValue';
import { useCan } from 'hooks/useCan';
import { date, daysDistance, formatCNPJ } from 'utils/format';
import {
  MdLocalPostOffice,
  MdLocalPhone,
  MdInbox,
  MdSignalCellularConnectedNoInternet0Bar,
  MdSignalCellular1Bar,
  MdSignalCellular3Bar,
  MdSignalCellular4Bar,
} from 'react-icons/md';
import { ImBlocked } from 'react-icons/im';
import { BsInboxFill } from 'react-icons/bs';
import { AiOutlineFileSearch } from 'react-icons/ai';
import DefaultImage from 'resources/images/company-default-image.png';

import {
  RiAddFill,
  RiFileUploadLine,
  RiMore2Fill,
  RiEdit2Fill,
  RiDeleteBin2Fill,
  RiRefreshLine,
  RiCloseLine,
  RiFileExcelLine
} from 'react-icons/ri';
import { Select } from 'components/Forms/Select';
import Create from './create';
import Delete from './delete';
import Import from './import';
import Export from './export';

export default () => {
  const toast = useToast();
  const initTagRef = React.useRef();
  const createCompanyCan = useCan({ permission_role: ['CREATE_COMPANY'] });
  const getCompanyCan = useCan({ permission_role: ['GET_COMPANY'] });
  const deleteCompanyCan = useCan({ permission_role: ['DELETE_COMPANY'] });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [tag, setTag] = useState([]);
  const [selectedsTags, setSelectedsTags] = useState([]);
  const [newTagName, setNewTagName] = useState('');
  const [limit, setLimit] = useState(20);
  const [sort, setSort] = useState('et.id');
  const [createItem, setCreateItem] = useState(false);
  const [newImportItem, setNewImportItem] = useState(false);
  const [exportCompanies, setExportCompanies] = useState(false);
  const [direction, setDirection] = useState('desc');
  const [deleteItem, setDeleteItem] = useState(0);

  const searchDelayed = useDelayedValue(search, 1000);

  const getCompanies = async (page = 0) => {
    try {
      nprogress.start();
      const { data } = await ClientCompany.index(
        `&tag=${tag}&search=${searchDelayed}&page=${page}&limit=${limit}&sort=${sort}&direction=${direction}`
      );
      nprogress.done();
      return data;
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar as empresas.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const clientNameStorage = localStorage?.getItem('@eConform-ClientName')
    ? localStorage?.getItem('@eConform-ClientName').replace(/ /g, '')
    : 'eConformClient';

  const companiesQuery = useQuery({
    queryKey: [`@eConform-getCompanies-${clientNameStorage}`, clientNameStorage, tag, page, searchDelayed, limit],
    queryFn: () => getCompanies(page),
    cacheTime: 120 * (60 * 1000),
    keepPreviousData: true,
  });

  const getTags = async (page = 0) => {
    try {
      nprogress.start();
      const { data } = await ClientCompany.tagsIndex(``);
      nprogress.done();
      return data;
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar as tags.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const tagsQuery = useQuery({
    queryKey: [`@eConform-getTags-${clientNameStorage}`, clientNameStorage, page, searchDelayed, limit],
    queryFn: () => getTags(),
    refetchOnMount: true,
    cacheTime: 120 * (60 * 1000),
    keepPreviousData: true,
  });

  const changeCreateTag = (e) => setNewTagName(e.target.value);

  const createTag = async () => {
    try {
      const { data } = await ClientCompany.tagsCreate({ tag: newTagName });
      toast({
        title: data.message,
        status: 'success',
        isClosable: true,
      });
      tagsQuery.refetch();
      return data;
    } catch (error) {
      toast({
        title: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const updateTag = async (companyId, tags, tagId) => {
    const tagsMap = tags?.map((itemMap) => ({
      id: Number(itemMap?.id),
    }));
    const tagsMapVerify = tags?.map((itemMap) => Number(itemMap?.id));
    const tagIdIncludes = tagsMapVerify.includes(Number(tagId));
    const tagsFilter = tags?.filter((tagItem) => Number(tagItem.id) !== Number(tagId));
    const tagsFilterMap = tagsFilter?.map((itemMap) => ({
      id: Number(itemMap?.id),
    }));
    const tagsConcat = tagsMap?.concat({ id: Number(tagId) });
    const tagsUpdated = tagIdIncludes ? tagsFilterMap : tagsConcat;

    try {
      const { data } = await ClientCompany.tagsUpdate(companyId, { tags: tagsUpdated });
      toast({
        title: data.message,
        status: 'success',
        isClosable: true,
      });
      companiesQuery.refetch();
      tagsQuery.refetch();
    } catch (error) {
      toast({
        title: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const deleteTag = async (tagId) => {
    try {
      const { data } = await ClientCompany.tagsDelete(tagId);
      toast({
        title: data.message,
        status: 'success',
        isClosable: true,
      });
      tagsQuery.refetch();
      return data;
    } catch (error) {
      toast({
        title: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const putUpdateCompany = async (id) => {
    toast({
      title: 'Atualizando empresa',
      description: (
        <>
          <Spinner thickness="2px" speed="0.4s" emptyColor="whiteAlpha.700" color="blue.400" size="sm" /> Aguarde...
        </>
      ),
      status: 'info',
      duration: 60000,
      isClosable: true,
    });
    try {
      await ClientCompany.updateDataItem(id);
      toast.closeAll();
      toast({
        title: 'Empresa atualizada',
        description:
          'Verifique os dados atualizados na ficha deinformações ou clicando na empresa e visitando a aba Dados.',
        status: 'success',
        isClosable: true,
      });
      companiesQuery.refetch();
    } catch (error) {
      toast.closeAll();
      toast({
        title: 'Não foi possível atualizar os dados desta empresa.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      setPage(0);
      setSearch(e.target.value);
    } else {
      setPage(0);
      setSearch(e.target.value);
    }
  };

  const handleClearSearch = () => {
    setPage(0);
    setSearch('');
  };

  const optionsFilterTags = tagsQuery?.data?.map((tag) => ({
    value: tag?.tag,
    label: tag?.tag,
  }));

  const handleFilterTag = (tags) => {
    setTag(tags?.map(({ value }) => value).join(','));
    setSelectedsTags(tags);
  };

  const handlePerRowsChange = (e) => {
    setLimit(e.target.value);
  };

  return (
    <>
      <Breadcrumbs
        title="Empresas"
        pages={[{ page: localStorage.getItem('@eConform-ClientName') || 'Início', link: '/client/dashboard' }]}
      />

      <Content>
        <Paper
          title="Empresas"
          fluid
          loading={companiesQuery.isLoading}
          fetching={companiesQuery.isFetching || companiesQuery.isRefetching}
          options={
            <ButtonGroup spacing="4">
              <Button
                type="button"
                leftIcon={<RiAddFill />}
                onClick={() => setCreateItem(true)}
                isDisabled={!createCompanyCan}
              >
                Adicionar
              </Button>
              <Button
                type="button"
                variant="ghost"
                leftIcon={<RiFileUploadLine />}
                onClick={() => setNewImportItem(true)}
                isDisabled={!createCompanyCan}
              >
                Importar
              </Button>
              <Button
                type="button"
                variant="ghost"
                leftIcon={<RiFileExcelLine />}
                onClick={() => setExportCompanies(true)}
                isDisabled={!getCompanyCan}
              >
                Exportar
              </Button>
            </ButtonGroup>
          }
        >
          {getCompanyCan ? (
            <>
              <Stack
                direction={{ base: 'column', xl: 'row' }}
                mb={
                  (companiesQuery.data?.total == 0 || companiesQuery.data?.items?.length == 0) &&
                    !search &&
                    !companiesQuery.isLoading
                    ? '0'
                    : '4'
                }
                justify="space-between"
              >
                <Stack direction={{ base: 'column', xl: 'row' }}>
                  <ButtonSearch
                    loading={companiesQuery.isFetching}
                    valueSearch={search}
                    total={companiesQuery.data?.total}
                    handleSearch={handleSearch}
                    handleClearSearch={handleClearSearch}
                    fetchData={() => getCompanies(0)}
                  />
                  <Box minW={{ base: '100%', xl: '282px' }}>
                    <Select
                      size="xs"
                      placeholder="Tags"
                      isClearable={false}
                      variant="flushed"
                      options={optionsFilterTags}
                      isMulti
                      onChange={(value) => {
                        handleFilterTag(value);
                      }}
                      value={selectedsTags}
                    />
                  </Box>
                </Stack>

                {companiesQuery.data?.items && companiesQuery.data?.total > 0 && !companiesQuery.isLoading && (
                  <>
                    <Stack direction={{ base: 'column', md: 'row' }} spacing="8" align="center">
                      <ShowingItemsLegend total={companiesQuery.data?.total} limit={limit} page={page} />
                      <Pagination
                        page={page}
                        handlePageChange={handlePageChange}
                        pagesInRange={companiesQuery.data?.pages_in_range}
                      />
                    </Stack>
                  </>
                )}
              </Stack>

              {companiesQuery.data?.items && companiesQuery.data?.total > 0 && !companiesQuery.isLoading ? (
                <>
                  <SimpleGrid columns={[1, 1, 1, 2, 2, 3, 4, 4, 5, 6]} spacing="4">
                    {companiesQuery.data?.items.map((company) => (
                      <Box
                        key={company.id}
                        borderWidth="1px"
                        bgColor="grayBlue.50"
                        borderColor="grayBlue.200"
                        borderRadius="3px"
                        _hover={{ bgColor: 'grayBlue.100', textDecoration: 'none' }}
                      >
                        <VStack
                          h="full"
                          justify="space-between"
                          color="grayBlue.600"
                          fontSize="sm"
                          spacing="4"
                          p="4"
                          alignItems="left"
                        >
                          <HStack w="full" justify="space-between" align="start">
                            <Link as={LinkRouterDom} to={`/client/companies/${company.id}`}>
                              <Image
                                w="100%"
                                h="50px"
                                borderRadius="3px 3px 0 0"
                                objectFit="contain"
                                src={
                                  company.logoSrc || DefaultImage
                                }
                                alt={company.name}
                              />
                            </Link>
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                aria-label="Options"
                                icon={<Icon as={RiMore2Fill} w={5} h={5} />}
                                variant="unstyle"
                                minW="auto"
                                color="grayBlue.400"
                                _hover={{ color: 'blue.400' }}
                              />
                              <MenuList>
                                <MenuItem
                                  as={LinkRouterDom}
                                  icon={<RiEdit2Fill />}
                                  to={`/client/companies/${company.id}`}
                                >
                                  Acessar
                                </MenuItem>
                                <MenuItem
                                  as={LinkRouterDom}
                                  icon={<RiRefreshLine />}
                                  onClick={() => putUpdateCompany(Number(company.id))}
                                >
                                  Atualizar dados
                                </MenuItem>
                                <MenuItem
                                  icon={<RiDeleteBin2Fill />}
                                  onClick={() => setDeleteItem(Number(company.id))}
                                  color="red.500"
                                >
                                  Excluir
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </HStack>

                          <Heading
                            as={LinkRouterDom}
                            to={`/client/companies/${company.id}`}
                            size="sm"
                            fontWeight="semibold"
                            color={company.enabled ? 'blue.400' : 'red.500'}
                            mb="0"
                          >
                            {company.name == 'undefined' ||
                              company.name == 'null' ||
                              company.name == 'Não encontrado' ||
                              !company.name
                              ? 'Não possui nome'
                              : company.name?.toString()}
                            {daysDistance(new Date(company.createdAt?.date), 30) && (
                              <Badge colorScheme="purple">Novo</Badge>
                            )}
                            {!company.enabled && <Badge colorScheme="red">Inativo</Badge>}
                            {company?.cnaes?.lowLevel?.length <= 0 &&
                              company?.cnaes?.mediumLevel?.length <= 0 &&
                              company?.cnaes?.highLevel?.length <= 0 && (
                                <Icon
                                  as={MdSignalCellularConnectedNoInternet0Bar}
                                  color="grayBlue.600"
                                  w={4}
                                  h={4}
                                  ms="2"
                                  title="Sem CNAEs e Licenças"
                                />
                              )}
                            {company?.cnaes?.lowLevel?.length > 0 &&
                              company?.cnaes?.mediumLevel?.length <= 0 &&
                              company?.cnaes?.highLevel?.length <= 0 && (
                                <Icon
                                  as={MdSignalCellular1Bar}
                                  color="green.400"
                                  w={4}
                                  h={4}
                                  ms="2"
                                  title="Risco Baixo"
                                />
                              )}
                            {company?.cnaes?.mediumLevel?.length > 0 && company?.cnaes?.highLevel?.length <= 0 && (
                              <Icon
                                as={MdSignalCellular3Bar}
                                color="yellow.400"
                                w={4}
                                h={4}
                                ms="2"
                                title="Risco médio"
                              />
                            )}
                            {company.cnaes?.highLevel.length > 0 && (
                              <Icon as={MdSignalCellular4Bar} color="red.400" w={4} h={4} ms="2" title="Risco Alto" />
                            )}
                          </Heading>

                          <Text>
                            <chakra.span fontSize="xs">Nome fantasia: </chakra.span>
                            {company.registrationFantasyName == 'undefined' ||
                              company.registrationFantasyName == 'null' ||
                              company.registrationFantasyName == 'Não encontrado' ||
                              !company.registrationFantasyName ? (
                              <chakra.span fontStyle="italic" fontSize="xs">
                                (Não possui)
                              </chakra.span>
                            ) : (
                              <strong>{company.registrationFantasyName}</strong>
                            )}
                            <br />
                            <chakra.span fontSize="xs">CNPJ: </chakra.span>
                            <strong>
                              {company.registrationCode ? formatCNPJ(company.registrationCode) : 'Não cadastrado'}
                            </strong>
                            <br />
                            <chakra.span fontSize="xs">
                              {company.systemCityName ? company.systemCityName : 'Não cadastrado'}
                              {company.systemStateAlias ? `, ${company.systemStateAlias}` : ''}
                            </chakra.span>
                          </Text>

                          <Text>
                            <chakra.span fontSize="xs">
                              {company?.registrationSituation === 'INAPTA'
                                ? 'SUSPENSA'
                                : company?.registrationSituation || 'Não cadastrado'}
                              {company.registrationCreatedAt ? (
                                <>
                                  {' '}
                                  desde
                                  {date(company.registrationCreatedAt, "dd' 'MMM' 'yyyy'")}
                                </>
                              ) : (
                                ''
                              )}
                            </chakra.span>
                            <br />
                            <strong>{company.totalCNPJ}</strong> CNPJ
                            {', '}
                            {/* <strong>{company.totalLicense} licenças</strong> cadastradas
                            <br /> */}
                            <strong>{company.totalFiles}</strong> documentos
                          </Text>

                          {/*  <Text fontSize="xs">
                            <Icon as={MdLocalPostOffice} mr="2" />
                            {company.contactEmail ? company.contactEmail : 'Email não cadastrado'}
                            <br />
                            <Icon as={MdLocalPhone} mr="2" />
                            {company.contactPhone ? company.contactPhone : 'Telefone não cadastrado'}
                          </Text> */}

                          <Popover>
                            <PopoverTrigger>
                              <Flex
                                gap={1}
                                wrap="wrap"
                                borderRadius="5px"
                                p="2"
                                cursor="pointer"
                                bg="grayBlue.200"
                                _hover={{ bg: 'grayBlue.300' }}
                              >
                                {company?.tags?.length <= 0 ? (
                                  <Text fontSize="xs" w="100%" textAlign="center">
                                    Clique aqui para adicionar tags
                                  </Text>
                                ) : (
                                  company?.tags?.map((tag) => (
                                    <Tag key={tag.id} size="sm" bgColor="blue.300" color="white">
                                      {tag.tag}
                                    </Tag>
                                  ))
                                )}
                              </Flex>
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              {/* <PopoverHeader>
                                <strong>Tags</strong>
                              </PopoverHeader> */}
                              <PopoverBody>
                                <Text fontSize="xs" fontWeight="bold" mb="2">
                                  Tags desta empresa:
                                </Text>
                                <Flex gap={1} wrap="wrap" bgColor="grayBlue.200" p="2">
                                  {company?.tags?.length <= 0 ? (
                                    <Text>Sem tags</Text>
                                  ) : (
                                    company?.tags?.map((tag) => (
                                      <Tag key={tag.id} size="sm" color="white" bgColor="blue.400">
                                        <TagLabel>{tag.tag}</TagLabel>
                                        <TagCloseButton
                                          cursor="pointer"
                                          title="Excluir tag desta empresa"
                                          onClick={() => updateTag(company?.id, company?.tags, tag?.id)}
                                        />
                                      </Tag>
                                    ))
                                  )}
                                </Flex>
                                <Divider my="2" />
                                <Text fontSize="xs" fontWeight="bold" mb="2">
                                  Selecione tags ou crie uma...
                                </Text>
                                <Flex gap={1} wrap="wrap">
                                  {tagsQuery?.data?.map((tag) => (
                                    <Tag key={tag.id} size="sm" color="white" bgColor="blue.400">
                                      <TagLabel
                                        cursor="pointer"
                                        title="Adicionar tag à esta empresa"
                                        onClick={() => updateTag(company?.id, company?.tags, tag?.id)}
                                      >
                                        {tag.tag}
                                      </TagLabel>
                                      <Popover closeOnBlur={false} placement="auto-end" initialFocusRef={initTagRef}>
                                        {({ isOpen, onClose }) => (
                                          <>
                                            <PopoverTrigger>
                                              <Box ml="5px" color="blue.100">
                                                <RiCloseLine
                                                  cursor="pointer"
                                                  fontSize="18px"
                                                  title="Excluir tag da lista principal"
                                                />
                                              </Box>
                                            </PopoverTrigger>
                                            <Portal>
                                              <PopoverContent>
                                                <PopoverCloseButton />
                                                <PopoverBody fontSize="sm" pe="50px">
                                                  <Box mb="10px">
                                                    Tem certeza que deseja excluir esta Tag da lista principal e de
                                                    todas as empresas?
                                                  </Box>
                                                  <HStack>
                                                    <Button
                                                      size="xs"
                                                      variant="solid"
                                                      colorScheme="red"
                                                      onClick={() => deleteTag(tag?.id)}
                                                    >
                                                      Sim
                                                    </Button>
                                                    <Button
                                                      size="xs"
                                                      variant="ghost"
                                                      colorScheme="gray"
                                                      onClick={onClose}
                                                      ref={initTagRef}
                                                    >
                                                      Não
                                                    </Button>
                                                  </HStack>
                                                </PopoverBody>
                                              </PopoverContent>
                                            </Portal>
                                          </>
                                        )}
                                      </Popover>
                                    </Tag>
                                  ))}
                                </Flex>
                              </PopoverBody>
                              <PopoverFooter>
                                <ButtonGroup size="sm" w="100%" isAttached>
                                  <Input size="sm" p="1" placeholder="nova tag..." onChange={changeCreateTag} />
                                  <Button onClick={createTag} borderRadius="0 3px 3px 0">
                                    Criar
                                  </Button>
                                </ButtonGroup>
                              </PopoverFooter>
                            </PopoverContent>
                          </Popover>
                        </VStack>
                      </Box>
                    ))}
                  </SimpleGrid>

                  <Stack
                    direction={{ base: 'column', md: 'row' }}
                    mt="8"
                    spacing="4"
                    align="center"
                    justify="space-between"
                  >
                    <ItemsPerPage
                      total={companiesQuery.data?.total}
                      limit={limit}
                      handlePerRowsChange={handlePerRowsChange}
                    />
                    <HStack direction={{ base: 'column', md: 'row' }} spacing="8" align="center" justify="end">
                      <ShowingItemsLegend total={companiesQuery.data?.total} limit={limit} page={page} />
                      <Pagination
                        page={page}
                        handlePageChange={handlePageChange}
                        pagesInRange={companiesQuery.data?.pages_in_range}
                      />
                    </HStack>
                  </Stack>
                </>
              ) : companiesQuery.data && search && !companiesQuery.isLoading ? (
                <Result
                  title={`Nada encontrado para "${search}"`}
                  description="Escolha outro termo para buscar."
                  icon={AiOutlineFileSearch}
                  options={
                    <Button type="button" variant="ghost" colorScheme="grayBlue" onClick={handleClearSearch}>
                      Voltar à lista
                    </Button>
                  }
                />
              ) : companiesQuery.data && !search && !companiesQuery.isLoading ? (
                <Result
                  title="Vazio"
                  description="Adicione uma ou mais empresas para administrá-las posteriormente."
                  icon={BsInboxFill}
                />
              ) : null}
            </>
          ) : (
            <Result
              title="Acesso restrito"
              description="Você não tem permissão para visualizar esta listagem."
              icon={ImBlocked}
            />
          )}
        </Paper>
      </Content>

      {createCompanyCan ? (
        <Create
          isOpen={createItem}
          onClose={() => {
            setCreateItem(false);
            companiesQuery.refetch();
          }}
        />
      ) : null}

      {createCompanyCan ? (
        <Import
          isOpen={newImportItem}
          setNewImportItem={setNewImportItem}
          onClose={() => {
            setNewImportItem(false);
            companiesQuery.refetch();
          }}
        />
      ) : null}

      {getCompanyCan ? (
        <Export
          isOpen={exportCompanies}
          setExportCompanies={setExportCompanies}
          onClose={() => {
            setExportCompanies(false);
            companiesQuery.refetch();
          }}
        />
      ) : null}

      {deleteCompanyCan ? (
        <Delete
          isOpen={deleteItem}
          id={deleteItem}
          onClose={() => {
            setDeleteItem(0);
            companiesQuery.refetch();
          }}
        />
      ) : null}
    </>
  );
};
