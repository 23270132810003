import React, { forwardRef } from 'react';

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  NumberInput as NumberInputChakra,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
} from '@chakra-ui/react';

const NumberInputBase = (
  { name = '', label = '', defaultValue = 0,
    min = 0, max = 100, step = 1, register,
    errors, helper,
    layout = 'horizontal' },
  ref
) => {
  return (
    <FormControl isInvalid={errors[name]}>

      <div style={{ display: layout === 'horizontal' ? 'flex' : 'block' }}>

        {!!label && <FormLabel w={layout === 'horizontal' ? '30%' : '100%'}>{label}</FormLabel>}

        <Box w={layout === 'horizontal' ? '70%' : '100%'}>
          <NumberInputChakra defaultValue={defaultValue} min={min} max={max} step={step} ref={ref}>
            <NumberInputField {...register(name)} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInputChakra>
          {helper && <FormHelperText>{helper}</FormHelperText>}
          {errors[name] && <FormErrorMessage>{errors[name].message}</FormErrorMessage>}
        </Box>

      </div>
    </FormControl>
  );
};

export const NumberInput = forwardRef(NumberInputBase);
