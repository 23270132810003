// @ts-nocheck
import React, { useCallback, useRef, useState } from 'react';
import { useDownloadExcel } from "react-export-table-to-excel";
import nprogress from 'nprogress';
import { ClientCompany } from 'services';

import ReactExport from "react-data-export";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';

import {
  chakra,
  useToast,
  Button,
  ButtonGroup,
  Box,
  VStack,
  HStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
  Modal as ModalChakra,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Grid,
  Heading,
  GridItem,
  Badge,
  Icon,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

import Modal from 'components/Modal';
import { Input } from 'components/Forms/Input';
import { useAuth } from 'context/auth';
import LogoEConform from 'resources/logo/official/econform-logo-OriginalComFundoTransparente.png';
import { date, formatCNPJ, formatCurrency } from 'utils/format';
import { useReactToPrint } from 'react-to-print';
import { GrDocumentExcel, GrDocumentPdf } from 'react-icons/gr';

const schema = Yup.object({
  file: Yup.mixed().required('Escolha um arquivo Excel para ser lido pela plataforma.'),
});

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default ({ isOpen, setExportCompanies, onClose = () => { } }) => {
  const toast = useToast();
  const { auth } = useAuth();
  const componentRef = useRef();

  const getCompaniesExport = async () => {
    try {
      nprogress.start();
      const { data } = await ClientCompany.exportItems(auth.current_client_id);
      nprogress.done();
      return data;
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar as empresas.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const clientNameStorage = localStorage?.getItem('@eConform-ClientName')
    ? localStorage?.getItem('@eConform-ClientName').replace(/ /g, '')
    : 'eConformClient';

  const companiesExportQuery = useQuery({
    queryKey: [`@eConform-getCompaniesExport-${clientNameStorage}`, clientNameStorage],
    queryFn: () => getCompaniesExport(),
    cacheTime: 120 * (60 * 1000),
    keepPreviousData: true,
  });

  const handleClose = () => {
    onClose();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '{}',
    copyStyles: true,
  });

  console.log('COMPANIES: ', companiesExportQuery?.data);

  const GridItemCustom = (props) => (
    <GridItem colSpan={props.colSpan} border="1px solid #CBD5E0" padding={2} textAlign="left" {...props}>
      <Box fontSize=".6rem" textTransform="uppercase" color='grayBlue.600'>
        {props.title}
      </Box>
      <Box fontWeight="semibold" fontSize=".7rem">
        {props.children}
      </Box>
    </GridItem>
  );

  const licenseBadge = (status) => (
    status === 'in_conformity' ? (
      <Badge colorScheme="green" variant='outline'>Em conformidade</Badge>
    ) : status === 'pending' ? (
      <Badge colorScheme="yellow" variant='outline'>Pendente</Badge>
    ) : status === 'license_expired' ? (
      <Badge colorScheme="red" variant='outline'>Vencida</Badge>
    ) : status === 'near_expiration' ? (
      <Badge colorScheme="purple" variant='outline'>Expirando</Badge>
    ) : status === 'renewing' ? (
      <Badge colorScheme="blue" variant='outline'>Renovando</Badge>
    ) : status === 'not_necessary' ? (
      <Badge colorScheme="grayBlue" variant='outline'>Não necessária</Badge>
    ) : (
      <Badge colorScheme="grayBlue" variant='outline'>Não necessária</Badge>
    )
  )

  const licenseBadgeToTable = (status) => (
    status === 'in_conformity' ? (
      <span style={{ color: '#2ED47A', fontWeight: 600 }}>Em conformidade</span>
    ) : status === 'pending' ? (
      <span style={{ color: '#FFC76B', fontWeight: 600 }}>Pendente</span>
    ) : status === 'license_expired' ? (
      <span style={{ color: '#F9867C', fontWeight: 600 }}>Vencida</span>
    ) : status === 'near_expiration' ? (
      <span style={{ color: '#A07BF9', fontWeight: 600 }}>Expirando</span>
    ) : status === 'renewing' ? (
      <span style={{ color: '#40B0F4', fontWeight: 600 }}>Renovando</span>
    ) : status === 'not_necessary' ? (
      <span style={{ color: '#c2cfe0', fontWeight: 600 }}>Não necessária</span>
    ) : (
      <span style={{ color: '#c2cfe0', fontWeight: 600 }}>Não necessária</span>
    )
  )

  const licenseBadgeString = (status) => (
    status === 'in_conformity' ? (
      'Em conformidade'
    ) : status === 'pending' ? (
      'Pendente'
    ) : status === 'license_expired' ? (
      'Vencida'
    ) : status === 'near_expiration' ? (
      'Expirando'
    ) : status === 'renewing' ? (
      'Renovando'
    ) : status === 'not_necessary' ? (
      'Não necessária'
    ) : (
      'Não necessária'
    )
  )

  const cnaesMap = (item) => {
    const result = 'PRIMÁRIA: ' + item?.cnaes?.primary_activity + ' - SECUNDÁRIAS: ' +
      item?.cnaes?.secondary_activities?.map((cnae, i) => cnae?.code_class + ', ')
    return String(result)
  }

  const licensesMap = (item) => {
    const result = item?.licenses?.map((license, i) => (
      license.name + ' - ' + licenseBadgeString(license.status)
    ))
    return String(result)
  }

  const registration_situationBadge = (status) => (
    status === 'INAPTA' ? (
      <Badge colorScheme="orange" variant='outline'>Suspensa</Badge>
    ) : status === 'BAIXADA' ? (
      <Badge colorScheme="red" variant='outline'>Baixada</Badge>
    ) : status === 'ATIVA' ? (
      <Badge colorScheme="green" variant='outline'>Ativa</Badge>
    ) : status === 'INATIVA' ? (
      <Badge colorScheme="red" variant='outline'>Inativa</Badge>
    ) : (
      <Badge colorScheme="grayBlue" variant='outline'>{status}</Badge>
    )
  )

  const registration_situationBadgeToTable = (status) => (
    status === 'INAPTA' ? (
      <span style={{ color: '#FFC76B', fontWeight: 600 }}>Suspensa</span>
    ) : status === 'BAIXADA' ? (
      <span style={{ color: '#F9867C', fontWeight: 600 }}>Baixada</span>
    ) : status === 'ATIVA' ? (
      <span style={{ color: '#2ED47A', fontWeight: 600 }}>Ativa</span>
    ) : status === 'INATIVA' ? (
      <span style={{ color: '#F9867C', fontWeight: 600 }}>Inativa</span>
    ) : (
      <span style={{ color: '#c2cfe0', fontWeight: 600 }}>{status}</span>
    )
  )

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

  const companiesExcel = [
    {
      columns: [
        { title: "NOME", width: { wpx: 300 } },// wpx pixels, wch char
        { title: "CNPJ", width: { wpx: 200 } },
        { title: "SITUAÇÃO", width: { wpx: 200 } },
        { title: "NATUREZA LEGAL", width: { wpx: 200 } },
        { title: "EMAIL", width: { wpx: 200 } },
        { title: "TELEFONE", width: { wpx: 200 } },
        { title: "CAPITAL", width: { wpx: 200 } },
        { title: "ENDEREÇO", width: { wpx: 200 } },
        { title: "CNAEs", width: { wpx: 200 } },
        { title: "LICENÇAS", width: { wpx: 200 } },
      ],
      data: companiesExportQuery?.data?.map(item => [
        { value: item.name },
        { value: formatCNPJ(item.registration_code) + ' - ' + item.type },
        {
          value: item.registration_situation + ' desde ' +
            date(item.registration_situation_date)
        },
        { value: item.legal_nature },
        { value: item.email },
        { value: item.phone },
        { value: formatCurrency(item.share_capital) },
        {
          value: `${item?.address?.street}, ${item?.address?.number}, ${item?.address?.complement} - ${item?.address?.neighborhood}, ${item?.address?.city}, ${item?.address?.state}`
        },
        {
          value: cnaesMap(item)
        },
        {
          value: licensesMap(item)
        },
      ])
    }
  ];
  /* { value: item.registration_code, style: { fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } } } } */

  const tableRef = useRef(null);
  console.log('TABLE REF: ', tableRef)
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef?.current,
    filename: clientNameStorage + '-RelatórioEmpresas-eConform',
    sheet: "Empresas",
  });

  return (
    <>
      <ModalChakra isOpen={isOpen} onClose={handleClose} size="xxl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Exportar empresas
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody bg="grayBlue.200">
            <Tabs>
              <TabList>
                <Tab>PDF</Tab>
                <Tab>Excel</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>

                  <ButtonGroup spacing="4" mb="6" color='white'>
                    <Button onClick={handlePrint} color='white' leftIcon={<Icon as={GrDocumentPdf} sx={{ path: { stroke: 'white' } }} />} size="sm" >
                      Download/Imprimir PDF
                    </Button>
                  </ButtonGroup>

                  <Box ref={componentRef}>
                    <HStack fontSize="xs">
                      <Text>Emitido por: </Text>
                      <img src={LogoEConform} width="70px" height="auto" alt="Logo eConform" />
                    </HStack>
                    <Box p="2" borderWidth={1} borderStyle="solid" borderColor="grayBlue.700">
                      <Grid templateColumns="repeat(12, 1fr)" gap={2} color="grayBlue.700" fontSize="14px" borderRadius={4}>
                        <GridItemCustom
                          colSpan={12}
                          position="relative"
                          justify="center"
                          align="center"
                          textAlign="center"
                        >
                          <Box position="absolute" top={3}>
                            Empresas de:
                          </Box>
                          <Heading as="h1" size="md" mb={0}>
                            {localStorage.getItem('@eConform-ClientName')}
                          </Heading>
                        </GridItemCustom>

                        {companiesExportQuery?.data?.map((company, i) => (
                          <GridItemCustom key={i} colSpan={12} borderWidth={1} borderStyle="solid" borderColor="grayBlue.700">
                            <Grid templateColumns="repeat(12, 1fr)" gap={2} color="grayBlue.700" fontSize="14px" borderRadius={4}>

                              <GridItemCustom colSpan={6} title="NOME">
                                <chakra.span fontSize={14}>{company.name}</chakra.span>
                              </GridItemCustom>
                              <GridItemCustom colSpan={3} title="CNPJ">
                                {formatCNPJ(company.registration_code)} - {company.type}
                              </GridItemCustom>
                              <GridItemCustom colSpan={3} title="SITUAÇÃO">
                                {registration_situationBadge(company.registration_situation)} desde {' '}
                                {date(company.registration_situation_date)}
                              </GridItemCustom>

                              <GridItemCustom colSpan={3} title="NATUREZA LEGAL">
                                {company.legal_nature}
                              </GridItemCustom>
                              <GridItemCustom colSpan={3} title="EMAIL">
                                {company.email}
                              </GridItemCustom>
                              <GridItemCustom colSpan={3} title="TELEFONE">
                                {company.phone}
                              </GridItemCustom>
                              <GridItemCustom colSpan={3} title="CAPITAL COMPARTILHADO">
                                {formatCurrency(company.share_capital)}
                              </GridItemCustom>

                              <GridItemCustom colSpan={12} title="ENDEREÇO">
                                {company?.address?.street}, {company?.address?.number}, {company?.address?.complement} {' - '}
                                {company?.address?.neighborhood}, {company?.address?.city}, {company?.address?.state}
                              </GridItemCustom>

                              <GridItemCustom colSpan={3} title="CNAEs">
                                <Box>PRIMÁRIA:<br /> {company?.cnaes?.primary_activity}</Box>
                                SECUNDÁRIAS:<br /> {company?.cnaes?.secondary_activities?.map((cnae, i) => (
                                  <span key={i}>{cnae?.code_class},  </span>
                                ))}
                              </GridItemCustom>
                              <GridItemCustom colSpan={9} title="LICENÇAS">
                                {company?.licenses?.map((license, i) => (
                                  <div key={i}>{license.name} - {licenseBadge(license.status)}</div>
                                ))}
                              </GridItemCustom>

                            </Grid>
                          </GridItemCustom>
                        ))}

                      </Grid>
                    </Box>
                  </Box>

                </TabPanel>
                <TabPanel>

                  <ButtonGroup spacing="4" mb="6" color='white'>
                    <Button type='button' onClick={onDownload} leftIcon={<Icon as={GrDocumentExcel} sx={{ path: { stroke: 'white' } }} />} size="sm">
                      Download Excel
                    </Button>
                  </ButtonGroup>

                  <TableContainer h={'50vh'} overflowY={'scroll'} bg={'white'} p={4} borderRadius={5}>
                    <Table variant='simple' ref={tableRef}>
                      <Thead>
                        <Tr style={{ height: '45px', fontSize: 14, background: '#f0f3f8', color: '#334d6e', borderStyle: 'solid', borderWidth: 1, borderColor: '#e1e7f0' }}>
                          <Th style={{ width: '550px' }}>NOME</Th>
                          <Th>CNPJ</Th>
                          <Th>SITUAÇÃO</Th>
                          <Th>NATUREZA LEGAL</Th>
                          <Th>EMAIL</Th>
                          <Th>TELEFONE</Th>
                          <Th>CAPITAL COMPARTILHADO</Th>
                          <Th style={{ width: '550px' }}>ENDEREÇO</Th>
                          <Th>MUNICÍPIO</Th>
                          <Th>ESTADO</Th>
                          <Th>CNAE</Th>
                          <Th style={{ width: '550px' }}>LICENÇAS</Th>
                          <Th>STATUS DAS LICENÇAS</Th>
                          <Th>VALIDADE DAS LICENÇAS</Th>
                        </Tr>

                      </Thead>
                      <Tbody>
                        {companiesExportQuery?.data?.map((company, i) => (
                          <Tr key={i} style={{ verticalAlign: 'top', background: '#f8f9fc', borderStyle: 'solid', borderWidth: 1, borderColor: '#e1e7f0' }} >
                            <Td style={{ fontSize: 14 }}>{company?.name}</Td>
                            <Td>{formatCNPJ(company.registration_code)} - {company.type}</Td>
                            <Td textTransform='capitalize'>{registration_situationBadgeToTable(company.registration_situation)} desde {' '}
                              {date(company.registration_situation_date)}</Td>
                            <Td>{company?.legal_nature}</Td>
                            <Td>{company?.email}</Td>
                            <Td>{company?.phone}</Td>
                            <Td>{formatCurrency(company.share_capital)}</Td>
                            <Td style={{ textTransform: 'capitalize' }}>{company?.address?.street}, {company?.address?.number}, {company?.address?.complement} {' - '}
                              {company?.address?.neighborhood}</Td>
                            <Td style={{ textTransform: 'capitalize' }}>{company?.address?.city}</Td>
                            <Td style={{ textTransform: 'capitalize' }}>{company?.address?.state}</Td>
                            <Td>CP: {company?.cnaes?.primary_activity}</Td>

                            <Td style={{ width: '400px' }}>
                              {/* {company?.licenses?.map((license, i) => (
                              <Box key={i}>{license?.name}</Box>
                              ))} */}
                              <Table variant='simple' style={{ width: '400px', padding: 0 }} >
                                <Tbody>
                                  {company?.licenses?.map((license, i) => (
                                    <Tr key={i}>
                                      <Td style={{ width: '400px', padding: 0 }}>{license?.name}</Td>
                                    </Tr>
                                  ))}
                                </Tbody>
                              </Table>
                            </Td>

                            <Td style={{ width: '300px' }}>
                              {/* {company?.licenses?.map((license, i) => (
                              <Box key={i}>{licenseBadgeToTable(license?.status)}</Box>
                              ))} */}
                              <Table variant='simple' style={{ width: '300px', padding: 0 }} >
                                <Tbody>
                                  {company?.licenses?.map((license, i) => (
                                    <Tr key={i}>
                                      <Td style={{ width: '300px', padding: 0 }}>{licenseBadgeToTable(license?.status)}</Td>
                                    </Tr>
                                  ))}
                                </Tbody>
                              </Table>
                            </Td>

                            <Td style={{ width: '300px' }}>
                              {/* {company?.licenses?.map((license, i) => (
                              <Box key={i}>{date(license?.valid_until)}</Box>
                              ))} */}
                              <Table variant='simple' style={{ width: '300px', padding: 0 }} >
                                <Tbody>
                                  {company?.licenses?.map((license, i) => (
                                    <Tr key={i}>
                                      <Td style={{ width: '300px', padding: 0 }}>{date(license?.valid_until)}</Td>
                                    </Tr>
                                  ))}
                                </Tbody>
                              </Table>
                            </Td>



                            {/* <Td>
                              <Box>
                                {company?.licenses?.map((license, i) => (
                                  <div key={i} style={{ width: '550px' }}>{license.name} - {licenseBadgeToTable(license.status)}</div>
                                ))}
                              </Box>
                            </Td> */}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>

                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="grayBlue" variant="ghost" onClick={handleClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalChakra>

    </>

  );
};
