import React from 'react';
import { Select as ChakraSelect, ChakraStylesConfig } from 'chakra-react-select';
import { useController } from 'react-hook-form';
import { Box, FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/react';

const Select = ({
  name = '',
  label = '',
  value,
  variant = 'filled',
  control,
  onChange,
  ref,
  isMulti = true,
  selectAllOption = false,
  options = [],
  isClearable = true,
  closeMenuOnSelect = false,
  placeholder = 'Selecione ou digite para buscar...',
  noOptionsMessage = () => 'Nenhuma opção encontrada',
  loadingMessage = () => 'Carregando',
  errors,
  helper,
  layout = 'horizontal',
  ...props
}) => {
  const chakraStyles: ChakraStylesConfig = {
    control: (provided) => ({
      ...provided,
      border: 0,
      ps: 0,
      pe: 0,
      minHeight: '40px',
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: 'grayBlue.50',
    }),
    valueContainer: (provided) => ({
      ...provided,
      border: variant === 'filled' ? '1px solid #f0f3f8' : '0',
      padding: '0.5rem',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: 'xs',
      fontWeight: 'normal',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 'xs',
    }),
  };

  options = selectAllOption && isMulti ? [{ value: 'all', label: 'Selecionar todos' }, ...options] : options;

  const ChakraSelectComponent = (props) => {
    return (
      <FormControl isInvalid={errors && errors[name]}>
        <div style={{ display: layout === 'horizontal' ? 'flex' : 'block' }}>
          {!!label && <FormLabel w={layout === 'horizontal' ? '30%' : '100%'}>{label}</FormLabel>}

          <Box w={layout === 'horizontal' ? '70%' : '100%'}>
            <ChakraSelect
              variant={variant}
              options={options}
              onChange={(selected) => {
                selectAllOption && isMulti && selected?.length > 0 && selected.find((option) => option.value === 'all')
                  ? onChange(options.slice(1).concat(value))
                  : !isMulti
                    ? onChange((selected && selected) || null)
                    : onChange(selected);
              }}
              isMulti={isMulti}
              isClearable={isClearable}
              closeMenuOnSelect={closeMenuOnSelect}
              noOptionsMessage={noOptionsMessage}
              loadingMessage={loadingMessage}
              value={value}
              chakraStyles={chakraStyles}
              placeholder={placeholder}
              ref={ref}
              {...props}
            />

            {helper && <FormHelperText>{helper}</FormHelperText>}
            {errors && errors[name] && <FormErrorMessage>{errors[name].message}</FormErrorMessage>}

          </Box>

        </div>
      </FormControl>
    );
  };

  if (control) {
    const {
      field: { onChange, value, ref },
      fieldState: { error },
    } = useController({
      name,
      control,
    });
    return <ChakraSelectComponent {...props} />;
  }

  return <ChakraSelectComponent {...props} />;
};

export { Select };
